@use '../../../styles/utils' as *;

.radioLabel {
  @include form-radio-checkbox-label;
}

.radioLabel + .radioLabel {
  margin-top: 10px;
}

.radioLabelDisabled,
.radioLabelReadOnly {
  opacity: $form-input-disabled-opacity;
}

.radioInput {
  @include form-radio;
}

.fieldErrorMessage {
  @include form-field-error-message;
}
