@use '../../../styles/utils' as *;

.checkboxSelectionLabel {
  @include form-selection-label;
}

.checkboxSelectionLabelIcon {
  margin-right: rem(12);
  width: rem(16);
}

.checkboxSelectionDisabled {
  opacity: $form-input-disabled-opacity;
}

.checkboxSelectionInput {
  @include form-selection;
}

.fieldErrorMessage {
  @include form-field-error-message;

  display: block;
}
