@use '../../../styles/utils' as *;

.numericStepper {
  @include form-field-spacing;
}

.numericStepperControls {
  align-items: center;
  display: flex;
}

.input {
  @include form-input;

  font-weight: 700;
  margin: 0 rem(8);
  text-align: center;
}

.label {
  @include form-input-label;

  align-items: center;
  display: flex;

  // target icon
  span {
    height: rem(20);
    margin-right: rem(10);
    width: rem(20);
  }
}

.fieldErrorMessage {
  @include form-field-error-message;
}
